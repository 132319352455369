<template>
  <ion-page>
    <ion-item class="margin">
      <ion-select v-model="selectedTable" interface="popover" placeholder="Vælg Område" style="width: 13vw;">
        <IonSelectOption value="">Alle</IonSelectOption>
        <IonSelectOption value="cleverhouse">CleverHouse</IonSelectOption>
        <IonSelectOption value="sunsystem">SunSystem</IonSelectOption>
      </ion-select>
      <ion-searchbar v-model="searchTerm" style="margin-left: 10px;"></ion-searchbar>
    </ion-item>

    <ion-content :fullscreen="true">
      <div class="margin">
        <ion-progress-bar v-if = 'loading' type="indeterminate"></ion-progress-bar>
        <table-lite  :is-static-mode="true" :columns="table.columns" :rows="table.rows"
          :total="table.totalRecordCount" :sortable="table.sortable"
          :page-options="table.pageOptions" :messages="table.messages"></table-lite>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonPage,
  IonItem,
  IonSearchbar,
  IonProgressBar,
  IonSelect,
  IonSelectOption
} from '@ionic/vue';
import TableLite from "vue3-table-lite/ts";
import { ref, computed, reactive, defineComponent } from 'vue';
import axios from 'axios';

export default defineComponent({
  name: "App",
  components: {
    TableLite,
    IonContent,
    IonPage,
    IonItem,
    IonProgressBar,
    IonSelect,
    IonSelectOption,
    IonSearchbar 
  },
  setup() {
    const descriptionLength = 80
    const selectedTable = ref("");
    const loading = ref(true);
    const searchTerm = ref(""); // Search text
    // Fake data
    const data = reactive([]);
    // Table config
    const table = reactive({
      columns: [
        {
          label: "Forretningsområde",
          field: "group",
          width: "8%",
          sortable: true,
        },
        {
          label: "Nummer",
          field: "number",
          width: "3%",
          sortable: true,
          isKey: true,
        },
        {
          label: "Navn",
          field: "name",
          width: "10%",
          sortable: true,
        },
        {
          label: "Beskrivelse",
          field: "description",
          width: "20%",
          sortable: true,
          display: function (row) {
            return'<div class="max-height">'+ row.description +'</div>'
            
          }
        },
        {
          label: "På Lager",
          field: "stock_quantity",
          width: "5%",
          sortable: true,
        },
        {
          label: "I Ordre",
          field: "in_order_quantity",
          width: "5%",
          sortable: true,
        },
        {
          label: "Til Rådighed",
          field: "available_quantity",
          width: "5%",
          sortable: true,
        },
        {
          label: "Bestilt",
          field: "purchased_quantity",
          width: "5%",
          sortable: true,
        },
        {
          label: "Leverings Dato",
          field: "deliveryDate",
          width: "8%",
          sortable: true,
        },
      ],
      rows: computed(() => {
          
        if (searchTerm.value == "" && selectedTable.value == "") {
          return data;
        }

        function include(key, value) {
          if(key && value) {
            return key.toString().toLowerCase().includes(value.toString().toLowerCase())
          }
          else {
            return false;
          }
        }

        const filteredData = data.filter(
          (x) =>
          include(x.group, selectedTable.value) ||
          include(x.number, searchTerm.value) ||
          include(x.name, searchTerm.value) ||
          include(x.group, searchTerm.value) ||
          include(x.description, searchTerm.value) ||
          include(x.stock_quantity, searchTerm.value) ||
          include(x.in_order_quantity, searchTerm.value) ||
          include(x.available_quantity, searchTerm.value) ||
          include(x.purchased_quantity, searchTerm.value) ||
          include(x.deliveryDate, searchTerm.value)

        );
        return filteredData;
        
      }),
      totalRecordCount: computed(() => {
        return table.rows.length;
      }),
      sortable: {
        order: "id",
        sort: "asc",
      },
      pageOptions: [
      {
          value: 10,
          text: 10
        },
      {
          value: 25,
          text: 25
        },
      {
          value: 50,
          text: 50
        },
        {
          value: 100,
          text: 100
        },
        {
          value: 500,
          text: 500
        },
        {
          value: 10000,
          text: "Alle"
        }
      ],
      messages: {
        pagingInfo: "Viser {1} af {2}",
        pageSizeChangeLabel: "Linjer:",
        gotoPageLabel: "Side:",
        noDataAvailable: "Indlæser Data.",
      },
    });
    return {
      searchTerm,
      table,
      data,
      loading,
      selectedTable
    };
  },
  created() {
    this.GetData();
  },
  methods: {
    async GetData() {
      this.loading = true;
      const productOrderLookup = {};
      axios.defaults.headers.common["Authorization"] = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiNjY2YTEyMTBiNDIzODAyNmZjYTA4MDUwM2U1NmRmNzU2MjAzZGY2N2EzMzQyMDUyYmI5NTRkYzZiZDE2MzdhZjBjNzAyNjdlMThlNTRmMmIiLCJpYXQiOjE2NzgzNjg4NzAuMTc5ODI0LCJuYmYiOjE2NzgzNjg4NzAuMTc5ODI5LCJleHAiOjE5OTM5ODgwNzAuMTY1NjYxLCJzdWIiOiIxMDI5MCIsInNjb3BlcyI6W119.Mf2MPTlxEJ0m4MkC_BrfY5PH6fEbjEV0JLg1WCJu3VaexTClNFv4r6T9mTb2D8BP9GEjtVAijwe5UXZdBgQs_ClmNRWo_VYCdigOoOkcn71NfjaXSbxn4zjJcLmxPwpgewJAhhkx-DuPqN3XHx7AbcPcvil3ZpeDMkeX1x6n0bF65J-wpqo94yzuXlAd6OW87Et1563i0gXC-LQxc95ulCUTaBxptrsL3gIzG7en1EzQReMtJ_dwv_VEgDSaetB9tTZbQAjQkMQzIXlTb-1sy8I5z-fR2Ox2W56vSxxoVl2ExphYKO10HPWdW5iHCR9wdEenYMte1cZYWDtWcpMVZYpzBn9uJH68_PrN_Fyj9YmGpdLb3XTCjoi57QDKIQFrFHxOI6rDS33iHafDBjgfR1v5J4KCMCZfwGl1NfQefEVpsDEnE22a-anu-2unzodkU_APjLUNB2D_Ef52e33VJNPpYuBQNGZ1LBEwFYINBVUz1yZU4tb7QcqZsWJ18kxFFq3BaN0tj_qReB7OXFazRhgi0VeVaz_Aqw2y_K2JO_NJKVwwT0HrJj3eHNJa38zdMGiK6C3GLwPmWZTA07teR8O_KOLoPLbhmRJIEDbWjZcZSz2cUsjcYD7amX_uZFFWhY8tRHaN-AND2Thx5yRx1dpqWJGlGrxGVfoeLF_jKyY";
      try {
        //Uses Local API.
        //const productResponse = await axios.get("http://localhost:59559/api/Rackbeat/GetRackbeatProducts");
        //const productOrderResponse = await axios.get("http://localhost:59559/api/Rackbeat/GetPurchaseOrders");
        
        
        const productResponse = await axios.get("https://swaggersoftcontrol.azurewebsites.net/api/Rackbeat/GetRackbeatProducts");
        const productOrderResponse = await axios.get("https://swaggersoftcontrol.azurewebsites.net/api/Rackbeat/GetPurchaseOrders");

        const productOrder = productOrderResponse.data;
        for (let i = 0; i < productOrder.length; i++) {
          const element = productOrder[i];

          if(element.preferred_delivery_date != null){
            const lines = element.lines;
            for (let j = 0; j < lines.length; j++) {
                productOrderLookup[lines[j].child_id] = element.preferred_delivery_date;
            }
          }
        }
        for (let i = 0; i < productResponse.data.length; i++) {         
          const element = productResponse.data[i];
          if (!(element.stock_quantity == 0 && element.in_order_quantity == 0 && element.available_quantity == 0 && element.purchased_quantity == 0)) {

            const toadd = {
                number: element.number,
                name: element.name,
                group: element.group.name,
                description: element.description,
                stock_quantity: element.stock_quantity,
                in_order_quantity: element.in_order_quantity,
                available_quantity: element.available_quantity,
                purchased_quantity: element.purchased_quantity
                
              }

              if (productOrderLookup[toadd.number]) {
                if(toadd.purchased_quantity == "0"){
                  toadd.deliveryDate = "Ingen leverings dato."
                }else {
                  toadd.deliveryDate = (new Date(productOrderLookup[toadd.number])).toLocaleDateString();
                }
                
              }else{
                toadd.deliveryDate = "Ingen leverings dato."
              }

            this.data.push(toadd)
          }
        }
        this.loading = false;
      } catch (error) {
        console.error("Axios error", error);
        return;
      }
    }
  }
});
</script>

<style>
.grid-border {
  border-style: solid;
  border-width: 1px;
  color: var(--ion-color-medium);
}

.grid-border-round {
  border-radius: 50px;
  z-index: 1;
}

.no-padding {
  padding: 0px;
  margin: 0px;
}

.max-height{
  max-height: 2.4em;
  overflow: hidden; /* Hide any overflow */
  text-overflow: ellipsis; /* Show an ellipsis (...) if the text overflows */
}

.margin {
  margin: 25px;
  margin-top: 0px;
  margin-bottom: 10px;
}

.progress-margin{
  margin-left: 25px;
  margin-right: 25px;
}

.text {
  color: var(--ion-text-color);
}
</style>